import {
    Component,
    ComponentFactoryResolver,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ChangeDetectorRef,
    Type,
} from "@angular/core";

export interface DynamicValue {
    component?: Type<unknown>;
    inputs?: Record<string, any>;
}

@Component({
    selector: "s25-ng-dynamic-content",
    template: `<ng-container #container></ng-container>`,
    standalone: true,
})
export class DynamicContentComponent implements OnInit {
    @Input() dynamicValue: DynamicValue;
    @ViewChild("container", { read: ViewContainerRef, static: true }) container: ViewContainerRef;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.loadComponent();
    }

    private loadComponent() {
        if (!this.dynamicValue) {
            return;
        }

        this.container.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicValue.component);
        const componentRef = this.container.createComponent(componentFactory);

        // assign @Input() values
        if (this.dynamicValue.inputs) {
            Object.keys(this.dynamicValue.inputs).forEach((key) => {
                (componentRef.instance as any)[key] = this.dynamicValue.inputs[key];
            });
        }

        this.cd.detectChanges();
    }
}
